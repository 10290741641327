import request from "./request";

$(function() {
    var galleryId = "#gallery";
    var container = $(galleryId);

    var imagesContainer = container.find(".carousel-inner");
    var indicatorsContainer = container.find(".carousel-indicators");
    var content = [];
    var body = $(".carousel-container");

    if (container.length > 0) {
        request("assets/json/gallery.json?v="+new Date().toISOString(), function(response) {
            var indicators = [],
                images = [];
            //content = [];
            if (response instanceof Array) {
                for (var i = 0; i < response.length; i++) {
                    var item = response[i];
                    // console.log(i, response[i]);
                    indicators.push(indicator(galleryId, i));
                    images.push(image(galleryId, item.image, i));
                    content.push(contentBuilder(item, i));
                    if (i === 0)
                        body.html(content[i]);
                }
            }
            indicatorsContainer.html(indicators);
            imagesContainer.html(images);
        });

        container.on("slide.bs.carousel", function(evt) {
            var nextIndex = evt.to;
            //console.log(nextIndex, content)
            body.fadeOut()
            setTimeout(function() {
                body.html(content[nextIndex])
                body.fadeIn()
            }, 500)
            // console.log(body)
        })
    }

    var staffContainer = $('#staff-container');

    if (staffContainer.length > 0) {
        request("assets/json/staff.json?v="+new Date().toISOString(), function(staff) {
            if (staff instanceof Array) {
                for (var i = 0; i < staff.length; i++) {
                    var current = staff[i];
                    var person = new Person(current);
                    staffContainer[0].appendChild(person.toString());
                }
            }
        })
    }

    var activeHours = $('#active-hours');

    if (activeHours.length > 0) {
        var today = new Date();
        var dayOfWeek = today.getDay() - 1;
        if (dayOfWeek < 0)
            dayOfWeek = 6;
        var allDays = activeHours.find("tr");
        if (allDays.length === 7) {
            var target = allDays[dayOfWeek];
            target.setAttribute("class", "table-light");
            var badge = document.createElement("span");
            badge.setAttribute("class", "align-text-bottom badge badge-secondary-lt ml-2");
            badge.innerHTML = "Today";
            $(target).find("td")[0].appendChild(badge)

        }
    }

});

/**
 *
 * @param content
 * @param id
 * @returns {Array<HTMLDivElement>}
 * <h4 class="text-capitalize mb-3">NETFC Newsletter</h4>
 *      <blockquote class="blockquote">
 *          <p class="mb-0">October 2018 Edition</p>
 *      <footer class="blockquote-footer">Brad Johnson, Jim Ratzlaff, Donnie Peters ,and David Holt<br></footer>
 *      </blockquote>
 * <a class="btn btn-outline-primary btn-block btn-sm" role="button" href="#">Read More</a></div>
 */
var contentBuilder = function(content, id) {

    var title = document.createElement("h4");
    title.setAttribute("class", "text-capitalize mb-3");
    title.innerHTML = content.title;

    var block = document.createElement("blockquote");
    block.setAttribute("class", "blockquote");

    var subtitle = document.createElement("p")
    subtitle.setAttribute("class", "mb-0");
    subtitle.innerHTML = content.subtitle;

    var extras = document.createElement("footer");
    extras.setAttribute("class", "ml-3 text-muted mb-2");
    extras.innerHTML = content.extras.join("<br/>");
    var readmore;
    if (content.link && content.linkText) {
        readmore = document.createElement("a");
        readmore.target = "_blank";
        readmore.setAttribute("class", "btn btn-outline-secondary btn-block btn-sm");
        readmore.href = content.link;
        readmore.innerHTML = content.linkText
    }

    block.appendChild(subtitle);

    return [title, block, extras, readmore];
}

/**
 *
 * @param gallery
 * @param image
 * @param id
 * @returns {HTMLDivElement} <div class="carousel-item"><img class="w-100 d-block" src="assets/img/Lindner%20feed.jpg" alt="Slide Image" height="300"></div>
 */
var image = function(gallery, image, id) {

    var div = document.createElement("div");
    div.setAttribute("class", id === 0 ? "carousel-item active" : "carousel-item");
    var img = document.createElement("img");
    img.setAttribute("class", "w-100 d-block");
    img.setAttribute("height", "300");
    img.setAttribute("alt", "Slide Image");
    img.setAttribute("src", image);
    div.appendChild(img);

    // console.log(div);

    return div;
};

/**
 *
 * @param gallery
 * @param id
 * @returns {HTMLLIElement} <li data-target="#carousel-1" data-slide-to="0" class="active"></li>
 */
var indicator = function(gallery, id) {

    var item = document.createElement("li");
    item.setAttribute("data-target", gallery);
    item.setAttribute("data-slide-to", id);
    if (id === 0)
        item.setAttribute("class", "active");

    return item;
};

var Person = (function(data) {
    // Object.assign(this, data);

    this.container = document.createElement("div");
    this.container.setAttribute("class", "card d-block thumbnail");

    this.tContainer = document.createElement("div");
    this.tContainer.setAttribute("class", "t-container");

    this.img = document.createElement("img");
    this.img.setAttribute("class", "card-img-top w-100 d-block")
    this.img.src = "assets/img/staff/" + data.image;

    this.details = document.createElement("div");
    this.details.setAttribute("class", "details");

    this.email = document.createElement("a");
    this.email.setAttribute("class", "title mb-0");
    this.email.setAttribute("generate", "mailto");
    this.email.innerHTML = data.email;

    this.phone = document.createElement("p");
    this.phone.setAttribute("class", "title mb-0");
    this.phone.innerHTML = data.phone;

    this.body = document.createElement("div");
    this.body.setAttribute("class", "card-body");

    this.name = document.createElement("h5")
    this.name.setAttribute("class", "card-title");
    this.name.innerHTML = data.name;

    this.title = document.createElement("h6");
    this.title.setAttribute("class", "text-muted card-subtitle");
    this.title.innerHTML = data.title;

    Person.prototype.toString = function() {

        this.body.appendChild(this.name)
        this.body.appendChild(this.title);

        this.details.appendChild(this.email);
        this.details.appendChild(this.phone);

        this.tContainer.appendChild(this.img);
        this.tContainer.appendChild(this.details);

        this.container.appendChild(this.tContainer);
        this.container.appendChild(this.body);


        var column = document.createElement("div");
        column.setAttribute("class", "col-md-4 my-2");

        column.appendChild(this.container);

        return column;

    }

});