$(function() {
    var navbar = $('.navbar');
    if (navbar) {
        var defaultTop = navbar.offset().top;
        window.onscroll = function(evt) {
            var bounds = navbar[0].getBoundingClientRect();

            if (defaultTop > navbar.offset().top) {
                if (navbar.hasClass("fixed-top")) {
                    navbar.removeClass("fixed-top");
                }
                //navbar.toggleClass("fixed-top")
            } else
            if (bounds.top <= 0) {
                if (!navbar.hasClass("fixed-top")) {
                    navbar.addClass("fixed-top");
                }
            }
        }
    }
});