import '../scss/style.scss';

import request from "./request";

import "./bs-animation";
import "./galleryhandler";
import "./menuhandler";
import "./svf";
import "./parallax";

$('.dropdown').on('click', function(evt) {
    var target = $(evt.target);

    if (target.data("toggle") === "collapse") {
        evt.preventDefault();
        console.log(evt.currentTarget, evt.target)
    }

});

$('#integrated-modal').on("show.bs.modal", function(evt) {
    var btn = $(evt.relatedTarget);
    var modal = $(this);

    var name = btn.closest(".box").find(".name");
    var data = btn.siblings("[data-type=details]")

    var target = modal.find(".modal-body > p");
    modal.find(".modal-title").html("More about " + name.html());
    target.html(data.html());


    console.log(btn, modal, data, name, target);
})

$('[generate=mailto]').map(function(x, item) {
    var href = $(item).html();
    //console.log(item, href, x)
    item.href =  "mailto:" + href.replace(/<(?:.|\n)*?>/gm, '');
})

// <div class="alert-body notice-item my-2">
//     <p class="notice-body"></p>
//     <a target="_blank" class="notice-link btn btn-block btn-primary-lt">Read More!</a>
// </div>

function calculateHrefToBase(location = window.location, href, pattern) {
    var path = location.pathname;
    var idx = href.indexOf(pattern);

    if (idx === -1) {
        return href;
    }

    var newHref = href.replace(pattern, "");
    var parts = path.split(pattern);
    var backticks = parts.length - 2;
    for (var i = 0; i < backticks; i++) {
        newHref = "../" + newHref
    }

    return newHref;
}

$(function() {
    // $('[data-include]').map(function(x, item) {
    //     var href = item.getAttribute('data-include');
    //     if (href) {
    //         request(href, { contentType: "text/html" }, function(data) {
    //             $(item).html(data);
    //         })
    //     }
    //
    // })

    request("assets/json/notice.json?q="+new Date().toISOString(), function(data) {
        console.log(data)
        if (data && data.active) {
            var container = $("#notice-container");
            container.removeClass("d-none");
            if (data.notices && data.notices.length > 0) {
                for (var i = 0; i < data.notices.length; i++) {
                    var item = data.notices[i];

                    console.log(i, item);
                    var itemContainer = $('<div/>');
                    var itemBody = $('<p/>');

                    itemContainer.addClass("alert-body notice-item");
                    itemBody.html(item.body);

                    itemContainer.append(itemBody);

                    if (item.link && item.linkText) {
                        // console.log(item)
                        var itemLink = $('<a/>');
                        itemLink.attr("target", "_blank");
                        itemLink.attr("href", item.link);
                        itemLink.text(item.linkText || "Read More!");
                        itemLink.addClass("notice-link btn btn-block btn-primary-lt");
                        itemContainer.append(itemLink)
                    }

                    // console.log(itemContainer);

                    container.append(itemContainer);
                }
            }
        }
    })
});
