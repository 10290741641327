export default function request(url, options, callback) {
    var contentType = "application/json";
    var method = "GET";
    if (typeof options === "function") {
        callback = options
        options = undefined
    }
    if (options) {
        if (options.contentType)
            contentType = options.contentType;
        if (options.method)
            method = options.method;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            // Typical action to be performed when the document is ready:
            if (callback) {
                if (contentType === "application/json")
                    callback(JSON.parse(xhttp.responseText));
                else {
                    callback(xhttp.responseText);
                }
            }
            //document.getElementById("demo").innerHTML = xhttp.responseText;
        }
    };
    xhttp.open(method, url, true);
    xhttp.setRequestHeader("Content-Type", contentType);
    xhttp.send();
}