import request from "./request";

var productsContainer = $('#svf-products');
var tabButtons = $('#svf-tabs');

if (productsContainer && tabButtons) {

    tabButtons.find('[data-toggle="list"]')
        .on("show.bs.tab", function(e) {
            var target = e.currentTarget;
            var targetID = target.href.substr(target.href.indexOf("#"));
            if ($(targetID)) {
                var table = $(targetID).find(".table-container");

                if (table.length > 0 && table[0].childNodes.length === 0) {
                    console.log("Load Data");
                    request("sabine/" + targetID.substr(1) + "_products.html",
                        {
                            contentType: "text/html"
                        },
                        function(html) {
                            table.html(html)
                        })
                }
            }
        })
}