$(document).ready(function(){

(function(){

	if(!('requestAnimationFrame' in window)) return;
	if(/Mobile|Android/.test(navigator.userAgent)) return;

	var backgrounds = [];

	$('[data-bs-parallax-bg]').each(function(){
		var el = $(this);
		var bg = $('<div>');

		bg.css({
			backgroundImage: el.css('background-image'),
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			position: 'absolute',
			height:'200%',
			width:'100%',
			top:0, left:0,
			zIndex: -100
		});

		bg.appendTo(el);
		backgrounds.push(bg[0]);

		el.css({
			position:'relative',
			background:'transparent',
			overflow: 'hidden',
		});
	});

	if(!backgrounds.length) return;

	var visible = [];
	var scheduled;

	$(window).on('scroll resize', scroll);

	scroll();

	function scroll(){

		visible.length = 0;

		for(var i = 0; i < backgrounds.length; i++){
			var rect = backgrounds[i].parentNode.getBoundingClientRect();

			if(rect.bottom > 0 && rect.top < window.innerHeight){
				visible.push({
					rect: rect,
					node: backgrounds[i]
				});
			}

		}

		cancelAnimationFrame(scheduled);

		if(visible.length){
			scheduled = requestAnimationFrame(update);
		}

	}

	function update(){

		for(var i = 0; i < visible.length; i++){
			var rect = visible[i].rect;
			var node = visible[i].node;

			var quot = Math.max(rect.bottom, 0) / (window.innerHeight + rect.height);

			node.style.transform = 'translate3d(0, '+(-50*quot)+'%, 0)';
		}

	}

})();
});
$('.dropdown').on('click', function(evt) {
    var target = $(evt.target);
    
    if (target.data("toggle") === "collapse") {
        evt.preventDefault();
        console.log(evt.currentTarget, evt.target)
    }

});

$('#integrated-modal').on("show.bs.modal", function(evt) {
    var btn = $(evt.relatedTarget);
    var modal = $(this);
    
    let name = btn.closest(".box").find(".name");
    let data = btn.siblings("[data-type=details]")
    
    let target = modal.find(".modal-body > p");
    modal.find(".modal-title").html("More about " + name.html());
    target.html(data.html());
    
    
    console.log(btn, modal, data, name, target);
})

$('[generate=mailto]').map(function(x, item) {
    var href = $(item).html();
    //console.log(item, href, x)
    item.href =  "mailto:" + href.replace(/<(?:.|\n)*?>/gm, '');
})
